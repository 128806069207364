<template>
  <v-col cols="6">
    <v-tooltip nudge-top="-5" color="#2f3640" top>
      <template v-slot:activator="{ on, attrs }">
        <v-img
          contain
          height="100"
          :aspect-ratio="1.5"
          class="tw-cursor-pointer"
          v-bind="attrs"
          v-on="on"
          :src="logo"
          @click.prevent="modalView = true"
        />
      </template>
      <span>Click to view</span>
    </v-tooltip>
    <v-dialog
      v-model="modalView"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card tile>
        <v-img contain :aspect-ratio="1.5" :src="logo"></v-img>
        <div
          class="tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-20 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"
        >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="modalView = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalView: false,
    };
  },
};
</script>

<style></style>
