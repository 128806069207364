<template>
  <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
export default {

}
</script>

<style>

</style>